import React from "react"
import { css } from "styled-components"

import Layout from "../components/layout"
import MockupImage from "../components/mockup-image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Download videos and gifs from Twitter" />
    <div
      style={{
        maxWidth: `700px`,
        marginBottom: `1.45rem`,
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <div>
        <h1 style={{ fontWeight: 900, lineHeight: "1.3em" }}>
          Save videos and gifs from Twitter directly to your device the easy
          way.
        </h1>
      </div>
      <div
        css={css`
          margin: 64px 0;
        `}
      >
        <a href={"https://apps.apple.com/app/id1507844407"}>
          <img
            src={require("../images/app-store-badge.svg")}
            width={200}
            alt={"Get iffy on the App Store!"}
          />
        </a>
      </div>

      <div>
        <MockupImage />
      </div>
    </div>
  </Layout>
)

export default IndexPage
